
import { defineComponent, toRefs } from 'vue';
import { DatePicker } from 'v-calendar';
import { YesNoEnum } from '@/enums/YesNoEnum';

export default defineComponent({
    components: { DatePicker },
    props: ['filters'],

    setup(props) {
        const { filters } = toRefs(props);

        return {
            form: filters,
            YesNoEnum,
        }
    }
});
